import Header from '../components/header'
import React, { useState } from 'react'
import './stitchcount.css' // Import the CSS file for styling

export default function StitchCount() {
    const [stitchcount, setStitchCount] = useState(0)
    const [rowcount, setRowCount] = useState(0)
    const [customCount, setCustomCount] = useState(4)

    const handleIncrementS1 = () => setStitchCount(Number(stitchcount) + 1)
    const handleDecrementS1= () => setStitchCount(stitchcount - 1)
    const handleIncrementCustomCount= () => setStitchCount(Number(stitchcount) + Number(customCount))
    const handleDecrementCustomCount= () => setStitchCount(Number(stitchcount) - Number(customCount))

    const handleIncrementR1 = () => setRowCount(Number(rowcount) + 1)
    const handleDecrementR1= () => setRowCount(rowcount - 1)

    const rowReset = () => { 
        setRowCount(0)
      }

    const stitchReset = () => { 
        setStitchCount(0)
      }



    return (
        <>
            <Header />
            <div className="stitchbackground">

            <div className="stitchrow">
                <h2 className="stitchtitle">Row: {rowcount}</h2>
                <div><button className="buttonleft" onClick={handleDecrementR1}>-1</button></div>
                <div><button className="buttonright" onClick={handleIncrementR1}>+1</button> </div>
                <p>
                    <label>
                        Set Row: 
                        <input
                            value={rowcount}
                            onChange={e => setRowCount(e.target.value)}
                            type="number"
                            inputMode="numeric"
                        />
                        </label>
                    &nbsp;&nbsp;&nbsp;
                    <button className="rowreset" onClick={rowReset}>Reset Row Count</button>             
                </p>
            </div>

            <div className="stitchcount">
                <h2 className="stitchtitle">Stitch: {stitchcount}</h2>
                    <div><button className="buttonleft" onClick={handleDecrementS1}>-1</button></div>
                    <div><button className="buttonright" onClick={handleIncrementS1}>+1</button> </div> 
                    <div><button className="buttonleft" onClick={handleDecrementCustomCount}>-{customCount}</button></div>
                    <div><button className="buttonright" onClick={handleIncrementCustomCount}>+{customCount}</button> </div>                  
                    <p>
                        <label>
                        Set Custom: 
                        <input
                            value={customCount}
                            onChange={e => setCustomCount(e.target.value)}
                            type="number"
                            inputMode="numeric"
                        />
                        </label>
                        &nbsp;&nbsp;&nbsp;
                        <button className="stitchreset" onClick={stitchReset}>Reset Stitch Count</button> 
                    </p>
            </div>

            </div>
            
        </>
    )

}