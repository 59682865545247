import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from './pages/home'
import Rook from './pages/rook'
import Rook3 from './pages/rook3'
import StitchCount from "./pages/stitchcount"
import TicTacToe from './pages/tictactoe'
import NoPage from "./pages/nopage"

export default function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route index element = {<Home/>} />
          <Route path="home" element = {<Home/>} />
          <Route path="rook" element = {<Rook/>} />
          <Route path="rook3" element = {<Rook3/>} />
          <Route path="stitchcount" element = {<StitchCount/>} />
          <Route path="tictactoe" element = {<TicTacToe/>} />
          <Route path="*" element={<NoPage/>} />
        </Routes>
      </BrowserRouter>

    </div>
  )
}