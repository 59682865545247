import { Link } from 'react-router-dom'
import Header from '../components/header'
import './home.css'
import rook from '../images/rook.jpeg'
import crochet from '../images/chrochet.jpeg'
import tictactoe from '../images/tictactoe.jpeg'
import icon from '../images/icon.png'

export default function Home() {
    return (
        <>
            <Header />
            <div class = "grid-container">
                <div class="grid-item">
                <Link to="/rook">
                    <img className ="rookicon" src={rook} alt="Rook Icon"></img>
                    <p>Rook</p>
                </Link></div>
                <div class="grid-item">
                <Link to="/stitchcount">
                    <img className ="crocheticon" src={crochet} alt="Crochet Icon"></img>
                    <p>Stitch Count</p>
                </Link></div>
                <div class="grid-item">
                <Link to="/tictactoe">
                    <img className ="tictactoeicon" src={tictactoe} alt="Tic Tac Toe Icon"></img>
                    <p>Tic Tac Toe</p>
                </Link></div>
            </div>
        </>
    )
}