import Header from '../components/header'
import React, { useState } from 'react'
import './rook3.css' // Import the CSS file for styling
import rook from '../images/rook.jpeg'

export default function Rook3() {

  const [scoreA, setScoreA] = useState(0)
  const [scoreB, setScoreB] = useState(0)
  const [scoreC, setScoreC] = useState(0)
  const [roundScoreA,setRoundScoreA] = useState(null)
  const [roundScoreB,setRoundScoreB] = useState(null)
  const [roundScoreC,setRoundScoreC] = useState(null)

  const teamAName="Team A"
  const teamBName ="Team B"
  const teamCName ="Team C"

  const handleIncrementRoundA = () => setScoreA(scoreA + Number(roundScoreA))
  const handleDecrementRoundA = () => setScoreA(scoreA - Number(roundScoreA))
  const handleIncrementRoundB = () => setScoreB(scoreB + Number(roundScoreB))
  const handleDecrementRoundB = () => setScoreB(scoreB - Number(roundScoreB))
  const handleIncrementRoundC = () => setScoreC(scoreC + Number(roundScoreC))
  const handleDecrementRoundC = () => setScoreC(scoreC - Number(roundScoreC))
  
  const handleScoreReset = () => { 
    setScoreA(0)
    setScoreB(0)
    setScoreC(0)
  }

  const handleRoundReset = () => {
    setRoundScoreA("")
    setRoundScoreB("")
    setRoundScoreC("")
  }

    return (
        <>
            <Header />
            <div className="scoreboard3">

            <div className="info3"> 
                <form>
                    <h3 className="infohead">Trump Color</h3>

                    <select className="trumpselect" name="color" id="color">
                        <option value=""></option>
                        <option value="black">⬛️ Black</option>
                        <option value="green">🟩 Green</option>
                        <option value="red">🟥 Red</option>
                        <option value="yellow">🟨 Yellow</option>
                    </select>

                <h3 className="infohead">Bid</h3>   
                <input className="bidnum" type="number" inputMode="numeric" min="50" max="200" step="5" placeholder="50"/>
                <p></p>
                <input className="resetbidtrump" type="reset" value="Reset Round" onClick={handleRoundReset}/>
                </form>
                <p className="r3space">&nbsp;</p>
                <p className ="reset"><button onClick={handleScoreReset}>Reset Entire Game</button> </p>
          </div>
          
          
          <div className="teama3">
            <h2 contentEditable="true">{teamAName}</h2>
              <p className="score">{scoreA}</p>
              <div className="buttons">
              <p>
                    <label className="roundInput">
                        Round Score:&nbsp; 
                        <input
                            value={roundScoreA}
                            onChange={e => setRoundScoreA(e.target.value)}
                            type="number"
                            inputMode="numeric"
                        />
                        </label>            
                </p>
                <p className="r3space">&nbsp;</p>
                <button onClick={handleDecrementRoundA}>- {roundScoreA}</button>
                <button onClick={handleIncrementRoundA}>+ {roundScoreA}</button>
              </div>
          </div>
          
          <div className="teamb3">
            <h2 contentEditable="true">{teamBName}</h2>
              <p className="score">{scoreB}</p>
              <div className="buttons">
              <p>
                    <label className="roundInput">
                        Round Score:&nbsp; 
                        <input
                            value={roundScoreB}
                            onChange={e => setRoundScoreB(e.target.value)}
                            type="number"
                            inputMode="numeric"
                        />
                        </label>            
                </p>
                <p className="r3space">&nbsp;</p>
                <button onClick={handleDecrementRoundB}>- {roundScoreB}</button>
                <button onClick={handleIncrementRoundB}>+ {roundScoreB}</button>
            </div>
        </div>

        <div className="teamc3">
            <h2 contentEditable="true">{teamCName}</h2>
              <p className="score">{scoreC}</p>
              <div className="buttons">
              <p>
                    <label className="roundInput">
                        Round Score:&nbsp; 
                        <input
                            value={roundScoreC}
                            onChange={e => setRoundScoreC(e.target.value)}
                            type="number"
                            inputMode="numeric"
                        />
                        </label>            
                </p>
                <p className="r3space">&nbsp;</p>
                <button onClick={handleDecrementRoundC}>- {roundScoreC}</button>
                <button onClick={handleIncrementRoundC}>+ {roundScoreC}</button>
            </div>
        </div>
        
      </div>
        </>
    )
}