import Header from '../components/header'
import React, { useState } from 'react'
import './rook.css' // Import the CSS file for styling
import rook from '../images/rook.jpeg'

export default function Rook() {

  const [scoreA, setScoreA] = useState(0)
  const [scoreB, setScoreB] = useState(0)

  const teamAName="Team A"
  const teamBName ="Team B"

  const handleIncrementA5 = () => setScoreA(scoreA + 5)
  const handleIncrementA25 = () => setScoreA(scoreA + 25)
  const handleIncrementA50 = () => setScoreA(scoreA + 50)
  const handleIncrementA100 = () => setScoreA(scoreA + 100)
  const handleDecrementA5 = () => setScoreA(scoreA - 5)
  const handleDecrementA25 = () => setScoreA(scoreA - 25)
  const handleDecrementA50 = () => setScoreA(scoreA - 50)
  const handleDecrementA100 = () => setScoreA(scoreA - 100)


  const handleIncrementB5 = () => setScoreB(scoreB + 5)
  const handleIncrementB25 = () => setScoreB(scoreB + 25)
  const handleIncrementB50 = () => setScoreB(scoreB + 50) 
  const handleIncrementB100 = () => setScoreB(scoreB + 100) 
  const handleDecrementB5 = () => setScoreB(scoreB - 5)
  const handleDecrementB25 = () => setScoreB(scoreB - 25)
  const handleDecrementB50 = () => setScoreB(scoreB - 50)
  const handleDecrementB100 = () => setScoreB(scoreB - 100)
  
  const handleReset = () => { 
    setScoreA(0)
    setScoreB(0)
  }

    return (
        <>
            <Header />
            <div className="scoreboard">

            <div className="info"> 
                <img className ="rookpic" src={rook} alt="Rook Icon"></img>

                <form>
                    <h3 className="infohead">Trump Color</h3>

                    <select className="trumpselect" name="color" id="color">
                        <option value=""></option>
                        <option value="black">⬛️ Black</option>
                        <option value="green">🟩 Green</option>
                        <option value="red">🟥 Red</option>
                        <option value="yellow">🟨 Yellow</option>
                    </select>

                <h3 className="infohead">Bid</h3>   
                <input className="bidnum" type="number" inputMode="numeric" min="50" max="200" step="5" placeholder="50"/>
                <p></p>
                <input className="resetbidtrump" type="reset" value="Reset Trump/Bid" />
                </form>
                <p className ="reset"><button onClick={handleReset}>Reset Scores</button> </p>
          </div>
          
          
          <div className="teama">
            <h2 contentEditable="true">{teamAName}</h2>
              <p className="score">{scoreA}</p>
              <div className="buttons">
                <button onClick={handleDecrementA5}>-5</button>
                <button onClick={handleDecrementA25}>-25</button>
                <button onClick={handleDecrementA50}>-50</button>
                <button onClick={handleDecrementA100}>-100</button>
                <p >&nbsp;</p>
                <button onClick={handleIncrementA5}>+5</button>
                <button onClick={handleIncrementA25}>+25</button> 
                <button onClick={handleIncrementA50}>+50</button> 
                <button onClick={handleIncrementA100}>+100</button> 
              </div>
          </div>
          
          <div className="teamb">
            <h2 contentEditable="true">{teamBName}</h2>
              <p className="score">{scoreB}</p>
              <div className="buttons">
                <button onClick={handleDecrementB5}>-5</button>
                <button onClick={handleDecrementB25}>-25</button>
                <button onClick={handleDecrementB50}>-50</button>
                <button onClick={handleDecrementB100}>-100</button>
                <p>&nbsp;</p>
                <button onClick={handleIncrementB5}>+5</button>
                <button onClick={handleIncrementB25}>+25</button>  
                <button onClick={handleIncrementB50}>+50</button> 
                <button onClick={handleIncrementB100}>+100</button>  
            </div>
        </div>
        
      </div>
        </>
    )
}