import { Link } from 'react-router-dom'
import './header.css'

export default function Header () {
    return (
        <>
        <Link to="/home">
            <button className = "homeButton" type="button">Home</button>
        </Link>
        <Link to="/rook">
        <button className = "rookButton" type="button">Rook</button>
        </Link>
        <Link to="/rook3">
        <button className = "rook3Button" type="button">Rook (3)</button>
        </Link>
        <Link to="/stitchcount">
        <button className = "stitchButton" type="button">Stitch Count</button>
        </Link>
        <Link to="/tictactoe">
        <button className = "homeButton" type="button">Tic Tac Toe</button>
        </Link>
        </>
    )
}
